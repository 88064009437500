import React from 'react'
import * as bgVideoStyles from './backgroundvideo.module.scss'
import { StyledLink } from '../components/styles'
import { FaAngleDown } from 'react-icons/fa'
import ParticlesBg from 'particles-bg'


const BackgroundVideo = () =>
  <section className={bgVideoStyles.background} id='backgroundvideo'>
    <ParticlesBg type="cobweb" color="#0d0f8f" bg={true} alt='particles' />
    <div className={bgVideoStyles.flex}>
      <div className={bgVideoStyles.text}>
        <div data-aos="zoom-up" data-aos-delay="700">Hi, I'm Ariel.</div>
      </div>
      <StyledLink to="myworks"
        activeClass="active"
        spy={true}
        smooth={true}
        offset={0}
        duration={500}>
        <FaAngleDown />
      </StyledLink>
    </div>
  </section>
export default BackgroundVideo;
