import * as toContactStyles from './tocontact.module.scss';
import TypeWriterEffect from 'react-typewriter-effect';
import { BouncingArrowLink } from '../components/styles';
import { FaArrowDown } from 'react-icons/fa'


function ToContact() {
      return (
            <section className={toContactStyles.background} id="to-contact">
                  <div className={toContactStyles.text}>
                        <TypeWriterEffect
                              textStyle={{
                                    fontFamily: "'Roboto Mono', monospace",
                                    color: '#3F3D56',
                                    fontWeight: 500,
                                    fontSize: '1.5em',
                              }}
                              startDelay={30}
                              cursorColor="#3F3D56"
                              multiText={[
                                    'Hey there,',
                                    'if this interests you,',
                                    'scroll down and get in touch.'
                              ]}
                              loop={true}
                              nextTextDelay={1}
                              typeSpeed={1}
                        alt='typewriter'/>
                  </div>
                  <div className={toContactStyles.fixBottom}>
                        <div className={toContactStyles.bounce}>
                              <BouncingArrowLink
                                    to="contact"
                                    activeClass="active"
                                    spy={true}
                                    smooth={true}
                                    offset={0}
                                    duration={500}
                                    alt='arrow'>
                                    <FaArrowDown/>
                              </BouncingArrowLink>
                        </div>
                  </div>
            </section>
      )
}
export default ToContact;