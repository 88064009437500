import React from 'react'
import * as footerStyles from './footer.module.scss'
import { FaGithub } from 'react-icons/fa'
import { FaLinkedin } from 'react-icons/fa'
import { FaFacebook } from 'react-icons/fa'


const Footer = () =>
    <footer className={footerStyles.footerStyle}>
        <div className={footerStyles.icons}>
            <a className={footerStyles.icon} href='https://www.github.com/arielshulman29' target="_blank" rel="noopener noreferrer">
                <FaGithub />
            </a>
            <a className={footerStyles.icon} href='https://linkedin.com/in/ariel-shulman-a6314218b' target="_blank" rel="noopener noreferrer">
                <FaLinkedin />
            </a>
            <a className={footerStyles.icon} href='https://www.facebook.com/ariel.shulman1' target="_blank" rel="noopener noreferrer">
                <FaFacebook />
            </a>
        </div>
    </footer>

export default Footer