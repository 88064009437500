import React, { Fragment } from 'react'
import './App.scss';
import Content from './pages/content'
import BackgroundVideo from './pages/backgroundvideo'
import Navbar from './components/navbar'
import Footer from './components/footer'


function App() {
  return (
    <Fragment>
      <BackgroundVideo />
      <Navbar />
      <Content />
      <Footer />
    </Fragment>
  );
}


export default App;
