
import { Link } from 'react-scroll'
import styled from 'styled-components'

export const StyledLink = styled(Link)`
color: #0d0f8f;
opacity:0.8;
text-decoration: none;
display:block;
font-size: 2em;
margin-top: auto;
margin-bottom: 1em;
font-weight: 100;
:hover
{
    cursor: pointer;
    text-decoration: none;
    opacity:1;
}
.active{
  text-decoration: none;
  opacity:1;
}
}
@media (max-width: 991px) {
  margin-bottom: 3em!important;
}
`;



export const BouncingArrowLink = styled(Link)`
color: #252934;
text-decoration: none;
display:block;
font-size: 2em;
margin-top: auto;
margin-bottom: 1em;
font-weight: 100;
:hover
{
    cursor: pointer;
    text-decoration: none;
    color: #000;
}
.active{
  text-decoration: none;
  color: #000;
}
}
@media (max-width: 991px) {
  margin-bottom: 3em!important;
}
`;

export const StyledNav = styled.nav`
position: sticky;
position: -webkit-sticky;
z-index:10;
top:0;
height:0;
padding: 0;
border: none;
`;

export const StyledMenu = styled.div`
display: flex;
background: rgba(0,0,0,0.8);
flex-direction: column;
@media (min-width: 992px) {
  background:transparent;
  flex-direction:row;
}
@media (max-width: 991px) {
   border: 1px solid rgb(45, 47, 158);
 }
`;

export const StyledLinkNav = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin: 1em; 
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  :hover
  {
      cursor: pointer;
      text-decoration: none;
      color: rgb(203, 255, 252);
  }
  &.active{
    text-decoration: none;
    color: rgb(0, 217, 255);
  }
`;

export const StyledBtn = styled.button`
background: transparent;
border: none;
color:#fff;
display:flex;
font-size: 30px;
z-index: 11;
padding:10px;
@media (min-width: 992px) {
  display:none;
}
`;