import * as aboutStyles from './about.module.scss';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

function About() {
      return (
            <section className={aboutStyles.background} id="about">
                  <div className={aboutStyles.noOverflow}>
                        <div className={aboutStyles.shadow}></div>
                        <Container className={aboutStyles.flex} fluid>
                              <Row>
                                    <Col xs={12} lg={4} className={aboutStyles.colFlex1}>
                                          <h1 className={aboutStyles.mobileHeader} >About Me</h1>
                                    </Col>
                                    <Col xs={12} lg={8} className={aboutStyles.colFlex2}>
                                          <h1 data-aos="fade-left" className={aboutStyles.header} >About Me</h1>
                                          <div data-aos="fade-up" className={aboutStyles.text}>
                                                My name is Ariel shulman and I'm a Full Stack
                                                Web developer. I started programming as a
                                                teenager and later on did my military service as
                                                a programmer as well. During the pandemic in 2020 I
                                                was travelling Australia and New Zealand, In order to
                                                support myself I started to freelance.
                                                I enjoy being creative, solving problems and learning new tecnologies.
                                                I use the most modern tools out there! These days
                                                I like to use: NodeJs, ReactJS, ExpressJS, AWS,
                                                MongoDB, SQLserver and Bootstrap
                                                </div>
                                    </Col>
                              </Row>
                        </Container>
                  </div>
            </section>
      );
}

export default About;
