import * as MyWorkStyles from './myworks.module.scss';
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

function MyWorks() {
      return (
            <section className={MyWorkStyles.background} id="myworks">
                  <Container className={MyWorkStyles.centerContainer} fluid>
                        <Row className={MyWorkStyles.rowFlex}>
                              <Col className={MyWorkStyles.colFlex} xs={12} md={6} lg={4}>
                                    <Card data-aos="fade-up" className={MyWorkStyles.cardDesign}>
                                          <Card.Img variant="top" src="/responsive-design.png" className={MyWorkStyles.imagesize} alt="responsiveDesign" />
                                          <Card.Body>
                                                <Card.Title  className={MyWorkStyles.title}>Responsive Design</Card.Title>
                                                <Card.Text>
                                                      Esthetic, modern, animated, responsive web apps that would look good on any device! Don't settle on designs given to you by a CMS, have your own vision come to life!</Card.Text>
                                          </Card.Body>
                                    </Card>
                              </Col>

                              <Col className={MyWorkStyles.colFlex} xs={12} md={6} lg={4}>
                                    <Card data-aos="fade-up" className={MyWorkStyles.cardDesign}>
                                          <Card.Img variant="top" src="/speed.png" className={MyWorkStyles.imagesize} alt="pageSpeed" />
                                          <Card.Body>
                                                <Card.Title className={MyWorkStyles.title}>Speed</Card.Title>
                                                <Card.Text>
                                                      Nowadays, SEO is the key to succes. Speed is something you have to take into account if you want to be at the top searches. I use speed oriented technologies like React.</Card.Text>
                                          </Card.Body>
                                    </Card>
                              </Col>

                              <Col className={MyWorkStyles.colFlex} xs={12} md={6} lg={4}>
                                    <Card data-aos="fade-up" className={MyWorkStyles.cardDesign}>
                                          <Card.Img variant="top" src="/modern.png" className={MyWorkStyles.imagesize} alt="modernTech" />
                                          <Card.Body>
                                                <Card.Title className={MyWorkStyles.title}>Latest News</Card.Title>
                                                <Card.Text>
                                                      I only develop with the most modern and popular technologies. Think forward and don't settle for nothing but the most advanced tools out there! ReactJs, NodeJs, Bootstrap and more.</Card.Text>
                                          </Card.Body>
                                    </Card>
                              </Col>

                        </Row>
                  </Container>
            </section>
      );
}

export default MyWorks;
