import { useState } from 'react'
import * as contactStyles from './contact.module.scss'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { send } from 'emailjs-com';


const Contact = () => {
  const [toSend, setToSend] = useState({
    from_name: '',
    from_email: '',
    message: '',
  });
  const [isSubmit, setIsSubmit] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    send(
      'service_ebxwcv8',
      'template_00wf75q',
      toSend,
      '4bcbf7fcdbf6c752ecd26ba094996103'
    )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
      })
      .catch((err) => {
        console.log('FAILED...', err);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <section className={contactStyles.background} id="contact">
      <Container fluid className={contactStyles.container}>
        <Row className={contactStyles.grid}>
          <h1 className={contactStyles.header} data-aos="fade-down">CONTACT</h1>
          <span data-aos="fade-left" className={contactStyles.smallText}>Feel free to leave your details if you want to know more!</span>
          <Col xs={10} md={4} className={contactStyles.formGrid}>
            {!isSubmit ? (
              <form onSubmit={onSubmit}>
                <input data-aos="fade-right" className={contactStyles.formField} type="text" name="from_name" placeholder="Name" value={toSend.to_name} onChange={handleChange} />
                <input data-aos="fade-left" className={contactStyles.formField} type="text" name="from_email" placeholder="Email" value={toSend.from_email} onChange={handleChange} />
                <textarea data-aos="fade-right" className={contactStyles.formField} type="textarea" style={{ 'resize': 'none' }} rows={3} name="message" placeholder="message" value={toSend.message} onChange={handleChange} />
                <button data-aos="fade-up" className={contactStyles.buttonCustom} type="submit">SUBMIT</button>
              </form>) :
              (<span className={contactStyles.thankYou}>Thank you for your interest I will get back to you shorty!</span>)}
          </Col>
        </Row>
      </Container>
    </section>)
}

export default Contact;