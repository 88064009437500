import About from './about'
import MyWorks from './myworks'
import ToContact from './tocontact'
import Contact from './contact'

function Content() {
  return (
    <div className="Content">
      <MyWorks />
      <About />
      <section id="allContact">
        <ToContact />
        <Contact />
      </section>
    </div>
  );
}

export default Content;
