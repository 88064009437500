import React, { useState, useEffect } from 'react'
import { StyledMenu, StyledNav, StyledLinkNav, StyledBtn } from './styles'
import useCollapse from 'react-collapsed';
import { FaList } from 'react-icons/fa';

const NavBar = () => {
    const [isExpanded, setExpanded] = useState(window.innerWidth >= 992);
    const { getCollapseProps, getToggleProps } = 
    useCollapse({ isExpanded, duration: 10 });
    const handleToggle = () => setExpanded((prevExpanded) => !prevExpanded);
    const handleToggleResize = () => {
      if ((window.innerWidth < 992 && isExpanded) || (window.innerWidth >= 992 && !isExpanded)) {
        handleToggle();
      }
    }
    useEffect(() => {
      window.addEventListener('resize',handleToggleResize);
      return () => {
        window.removeEventListener('resize',handleToggleResize);
      }
    });
    
    return (
      <StyledNav>
          <StyledBtn {...getToggleProps({
            onClick: handleToggle,
          })}>
              <FaList />
              </StyledBtn>
        <StyledMenu {...getCollapseProps()} id="navMenu">
          <StyledLinkNav
            to="backgroundvideo"
            activeClass="active"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            onClick={handleToggleResize}>
            HOME
  </StyledLinkNav>
          <StyledLinkNav to="myworks"
            activeClass="active"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            onClick={handleToggleResize}>
            METHODS
  </StyledLinkNav>
          <StyledLinkNav to="about"
            activeClass="active"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            onClick={handleToggleResize}>
            ABOUT
  </StyledLinkNav>
          <StyledLinkNav activeClass="active"
            to="allContact"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            onClick={handleToggleResize}>
            CONTACT
  </StyledLinkNav>
        </StyledMenu>
      </StyledNav>
    )
  }
  export default NavBar;